import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function Valuechip(props) {
  return (
    <>
   
      <Stack direction="row" spacing={1}>
        <Chip
          label={`${props.valuePrimary} ${props.unit}`}
          sx={{ backgroundColor: "#F5FA0C", color: "black" }}
        />
        <Chip
          label={`${props.valueSecondary} ${props.unit}`}
          sx={{ backgroundColor: "#176C44", color: "#FFFFFF", }}
        />
      </Stack>
    </>
  );
}
