import { React, useState, useEffect } from "react";
import NavBar from "../Dashboard/Navbar/navbar";
import Header from "../Dashboard/Headers/header";
import overViewStyles from "./overview.module.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import GridBgImg from "../../assets/images/card.svg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { fetchAllWellData } from "../../components/Services/api-services";
import { showToaster } from "../../components/Services/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Typography, Box } from "@mui/material";
import PageLoader from "../Services/pageLoader";
import { useHistory } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#176C4433",
    color: "#2B2B2B",
    fontWeight: "500",
    fontSize: 16,
    fontFamily: "Poppins",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Overview() {
  const [rows, setRows] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  let history = useHistory();
  const fetchAllWellDataApi = () => {
    setPageLoad(true);
    try {
      fetchAllWellData().then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          setRows(response.data);
          setPageLoad(false);
        } else {
          setPageLoad(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setPageLoad(false);

      //   formik.resetForm();
      showToaster("something went wrong", "warning");
    }
  };
  const handleRowClick = (well_id) => {
    history.push({
      pathname: "/drilling-optimisation",
      state: well_id,
    });
  };
  useEffect(() => {
    fetchAllWellDataApi();
  }, []);

  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div className={overViewStyles.dashboard_container}>
        <div className={overViewStyles.nav_container}>
          <NavBar />
        </div>
        <div className={overViewStyles.dashboard_item_container}>
          <Header />
          <div className={overViewStyles.grid_container}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Paper
                  //    elevation={3}
                  style={{
                    textAlign: "center",
                    backgroundImage: `url(${GridBgImg})`,
                    backgroundSize: "100% 100%", // Stretch the image to fit the Paper completely
                    backgroundRepeat: "no-repeat", // Prevent tiling of the image
                    backgroundPosition: "center", // Center the image within the Paper
                    height: "120px", // Set the height for the Paper component
                    boxShadow: "none", //
                    textAlign: "center", //
                    padding: "20px",
                    alignItems: "center",
                  }}
                >
                  <span className={overViewStyles.active}>ACTIVE WELLS</span>
                  <span className={overViewStyles.active_items}>
                    <div className={overViewStyles.active_value}>
                      {rows && rows?.active_wells}/
                    </div>
                    <div className={overViewStyles.active_value_sub}>20</div>
                  </span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  //    elevation={3}
                  style={{
                    textAlign: "center",
                    backgroundImage: `url(${GridBgImg})`,
                    backgroundSize: "100% 100%", // Stretch the image to fit the Paper completely
                    backgroundRepeat: "no-repeat", // Prevent tiling of the image
                    backgroundPosition: "center", // Center the image within the Paper
                    height: "120px", // Set the height for the Paper component
                    boxShadow: "none", //</Grid>
                    textAlign: "center", //
                    padding: "20px",
                    alignItems: "center",
                  }}
                >
                  <span className={overViewStyles.active}>
                    Avg. drilling completed (%)
                  </span>
                  <span className={overViewStyles.active_items}>
                    <div className={overViewStyles.active_value}>
                      {rows && parseFloat(rows?.drilling_completed.toFixed(2))}
                    </div>
                    <div className={overViewStyles.active_value_sub}>%</div>
                  </span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  //    elevation={3}
                  style={{
                    textAlign: "center",
                    backgroundImage: `url(${GridBgImg})`,
                    backgroundSize: "100% 100%", // Stretch the image to fit the Paper completely
                    backgroundRepeat: "no-repeat", // Prevent tiling of the image
                    backgroundPosition: "center", // Center the image within the Paper
                    height: "120px", // Set the height for the Paper component
                    boxShadow: "none", //</Grid>
                    textAlign: "center", //
                    padding: "20px",
                    alignItems: "center",
                  }}
                >
                  <span className={overViewStyles.active}>Avg. ROP</span>
                  <span className={overViewStyles.active_items}>
                    <div className={overViewStyles.active_value}>
                      {rows && parseFloat(rows?.average_rop.toFixed(2))}
                    </div>
                    <div className={overViewStyles.active_value_sub}>m/hr</div>
                  </span>
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div className={overViewStyles.overview_table}>
            <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
              <Table
                sx={{ minWidth: 700, borderRadius: "10px" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell>Sl No</StyledTableCell> */}
                    <StyledTableCell align="center">Well ID</StyledTableCell>
                    <StyledTableCell align="center">
                      Current depth
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Target depth
                    </StyledTableCell>
                    <StyledTableCell align="center">Current ROP</StyledTableCell>
                    <StyledTableCell align="center">
                      Expected time to complete
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows &&
                    rows?.data.map((row, index) => (
                      <StyledTableRow
                        key={row.id}
                        onClick={() => handleRowClick(row.well_id)}
                        sx={{ cursor: "pointer" }}
                      >
                        {/* <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          {row.well_id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.depth_m}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.target_depth}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.rop_m_per_hr}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.expected_time}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
