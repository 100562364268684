
// import Dashboard from './components/dashboard';
import LoginPage from '../src/components/Auth/Login/login';
import Dashboard from '../src/components/Dashboard/Home/home';
import PowerBI from './components/PowerBI/report';
import Overview from './components/Overview/overview';
// import './App.css'
import { Route, Switch, BrowserRouter } from "react-router-dom";
function App() {

  return (
    <BrowserRouter >
      <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route path="/drilling-optimisation" exact component={Dashboard} />
        <Route path="/analytics" exact component={PowerBI} />
        <Route path="/overview" exact component={Overview} />
      </Switch>
    </BrowserRouter >
  );
}

export default (App);