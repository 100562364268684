
import axios from "axios";
  
  let baseUrl="https://oshainsider.techvantagesystems.com";

export const psoOptimization = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`https://oilandgasapi.techvantagesystems.com/pso_optimization`, body)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            resolve({ status: 101, message: "No response from the server" });
          }
        })
        .catch((e) => {
          resolve({ status: 500, message: "No response from the server" });
        });
    });
};
export const fetchWellIds = () => {
  return new Promise((resolve, reject) => {
      axios.get(`https://oilandgasapi.techvantagesystems.com/get_well_ids`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const fetchWellData = (body) => {
  return new Promise((resolve, reject) => {
      axios.post(`https://oilandgasapi.techvantagesystems.com/get_latest_timestamp_data`,body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};
export const fetchAllWellData = (body) => {
  return new Promise((resolve, reject) => {
      axios.get(`https://oilandgasapi.techvantagesystems.com/get_current_well`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: "No response from the server" });
      });
  });
};