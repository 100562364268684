import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { showToaster } from "../Services/helper";
import PageLoader from "../../components/Services/pageLoader";
import "./powerbi.css";
import {
  getAccessToken,
  getToken,
  getServerStatus,
  upgrateServerTime,
  suspendServerTime,
  azureAccessToken,
  getEmbededUrl,
  PowerbiRefresh,
} from "./token-service";
import NavBar from "../Dashboard/Navbar/navbar";
import Header from "../Dashboard/Headers/header";
export default function Dashboard(props) {
  const history = useHistory();
  const [accessToken, setGetToken] = useState(undefined);
  const [embededUrl, setEmbededUrl] = useState(false);
  const [serverToken, setServerToken] = useState(undefined);
  const [serverState, setServerState] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const MINUTE_MS = 60000;
  const [report, setReport] = useState(null);

  const serverStatus = () => {
    try {
      setPageLoad(true);
      azureAccessToken().then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status == 200
        ) {
          if (response.data !== undefined && response.data !== "") {
            if (
              response.data !== undefined &&
              response.data !== null &&
              response.data !== ""
            ) {
              let AccessToken = "Bearer " + response.data.access_token;
              await getServerStatus(AccessToken).then(async (response) => {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== undefined &&
                  response.data !== null &&
                  response.data !== "" &&
                  response.data.properties !== undefined &&
                  response.data.properties !== null &&
                  response.data.properties !== ""
                ) {
                  if (
                    response.data.properties.state !== undefined &&
                    response.data.properties.state !== null &&
                    response.data.properties.state !== ""
                  ) {
                    let serverStatus = response.data.properties.state;
                    console.log(serverStatus, "serverStatus");
                    setServerState(serverStatus);
                    setShowModal(true);
                    setPageLoad(false);
                  }
                }
              });
            }
          }
        } else {
          setPageLoad(false);
        }
      });
    } catch (e) {
      setPageLoad(false);
    }
  };

  useEffect(() => {
    retreivePowerBiCredentials();
    serverStatus();
    const interval = setInterval(() => {
      console.log("Logs every minute");
      try {
        azureAccessToken().then(async (response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status == 200
          ) {
            if (response.data !== undefined && response.data !== "") {
              if (
                response.data.result !== undefined &&
                response.data.result !== null &&
                response.data.result !== ""
              ) {
                let AccessToken = "Bearer " + response.data.result.access_token;
                setServerToken(AccessToken);
                await getServerStatus(AccessToken).then(async (response) => {
                  if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.data !== undefined &&
                    response.data !== null &&
                    response.data !== "" &&
                    response.data.properties !== undefined &&
                    response.data.properties !== null &&
                    response.data.properties !== ""
                  ) {
                    if (
                      response.data.properties.state !== undefined &&
                      response.data.properties.state !== null &&
                      response.data.properties.state !== ""
                    ) {
                      let serverStatus = response.data.properties.state;

                      if (serverStatus == "Paused") {
                        // upgradeServerTime("triger")
                      } else {
                        if (report == null) return;
                        await report.refresh();
                      }
                    }
                    // window.location.reload()
                  }
                });
              }
            }
          } else {
          }
        });
      } catch (e) {}
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  useEffect(() => {
    console.log("derarar");
    try {
      azureAccessToken().then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status == 200
        ) {
          if (response.data !== undefined && response.data !== "") {
            if (
              response.data.result !== undefined &&
              response.data.result !== null &&
              response.data.result !== ""
            ) {
              let AccessToken = "Bearer " + response.data.result.access_token;
              setServerToken(AccessToken);
              await getServerStatus(AccessToken).then(async (response) => {
                if (
                  response !== "" &&
                  response !== undefined &&
                  response !== null &&
                  response.data !== undefined &&
                  response.data !== null &&
                  response.data !== "" &&
                  response.data.properties !== undefined &&
                  response.data.properties !== null &&
                  response.data.properties !== ""
                ) {
                  if (
                    response.data.properties.state !== undefined &&
                    response.data.properties.state !== null &&
                    response.data.properties.state !== ""
                  ) {
                    let serverStatus = response.data.properties.state;

                    if (serverStatus == "Succeeded") {
                      setTimeout(() => {
                        // retreivePowerBiCredentials()
                      }, 5000);
                    } else if (serverStatus == "Paused") {
                      setTimeout(() => {
                        // upgradeServerTime()
                      }, 5000);
                    } else {
                      setTimeout(() => {
                        // upgradeServerTime()
                      }, 5000);
                    }
                  }
                  // window.location.reload()
                }
              });
            }
          }
        } else {
        }
      });
    } catch (e) {}
  }, []);

  const retreivePowerBiCredentials = async () => {
    try {
      let tid = process.env.REACT_APP_TENANTID;
      var details = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        grant_type: process.env.REACT_APP_GRAND_TYPE,
        scope: process.env.REACT_APP_SCOPE,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      };

      await getToken().then(async (response) => {
        console.log(response, "resss");
        if (response !== "" && response !== undefined && response !== null) {
          if (
            response.data !== undefined &&
            response.data !== null &&
            response.data !== ""
          ) {
            let AccessToken = "Bearer " + response.data.access_token;

            let data = {
              datasets: [
                {
                  id: process.env.REACT_APP_DATASETID,
                },
              ],
              reports: [
                {
                  id: process.env.REACT_APP_REPORT_ID,
                },
              ],
            };

            await getAccessToken(data, AccessToken).then(async (response) => {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status == 200
              ) {
                if (response.data !== undefined && response.data !== "") {
                  setGetToken(response.data.token);
                }
              } else {
              }
            });
            await getEmbededUrl(
              AccessToken,
              process.env.REACT_APP_REPORT_ID
            ).then((response) => {
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status == 200
              ) {
                if (response.data !== undefined && response.data !== "") {
                  setEmbededUrl(response.data.embedUrl);
                }
              } else {
              }
            });
          }
        } else {
        }
      });
    } catch (e) {}
  };

  return (
    <>
      {/* <PageLoader showLoader={pageLoad} /> */}
      <div className="dashboard_container">
        <div className="nav_container">
          <NavBar />
        </div>

        <section>
          <Header />
          {/* <div className="name-container">👋🏼 Hello Evano</div> */}
          <div className="col-12 w-100 powerBiContainer">
            <iframe
              title="Oil & Gas"
              width="95%"
              height="641.25"
              src="https://app.powerbi.com/view?r=eyJrIjoiMzhlZGJlMmYtMTE0OS00MzRlLWFkMzgtODE2ZWFiMGJjNWQ3IiwidCI6ImIxZjc3YjY3LWU2NjktNDI1ZS04NTkwLTdhMjBhYjNiYThmMCIsImMiOjEwfQ%3D%3D"
            ></iframe>
            {/* <PowerBIEmbed
                            embedConfig={{
                                type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                                id: process.env.REACT_APP_REPORT_ID,
                                embedUrl: embededUrl,
                                // embedUrl:"https://app.powerbi.com/groups/77c9dbc6-7914-4b91-bffc-7401d0cd7540/reports/366c1bc3-6c90-4bcc-8e44-08613048cca3/ReportSection?experience=power-bi",
                                accessToken: accessToken,
                                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                                settings: {
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false,
                                        },
                                    },
                                    // visualizations: {
                                    //     salesVisual: {
                                    //         filters: [salesVisualFilter]
                                    //     }
                                    // },
                                    background: models.BackgroundType.Transparent,
                                },
                            }}
                            eventHandlers={
                                new Map([
                                    [
                                        "loaded",
                                        function () {
                                            console.log("Report loaded");
                                        },
                                    ],
                                    [
                                        "rendered",
                                        function () {
                                            console.log("Report rendered");
                                        },
                                    ],
                                    [
                                        "error",
                                        function (event) {
                                            console.log(event.detail);
                                        },
                                    ],
                                    ["visualClicked", () => console.log("visual clicked")],
                                    ["pageChanged", (event) => console.log(event)],
                                ])
                            }
                            cssClassName={props.location?.state?.fromProcessLog ? "report-style-class" : "report-style-osha"}
                            // cssClassName={"reportClass"}

                            getEmbeddedComponent={(embeddedReport) => {
                                window.report = embeddedReport;
                                console.log("getEmbeddedComponent", report);
                                setReport(embeddedReport);
                            }}
                        /> */}
          </div>
        </section>
      </div>
    </>
  );
}
