import Swal from "sweetalert2";
// import jwt from "jwt-simple";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const showToaster = (message, type) => {
  Toast.fire({
    icon: type,
    title: message,
  });
};
export const getUserItem = (type) => {
  let getUserInfo = localStorage.getItem("Compliancify");
  console.log(getUserInfo,"getUserInfo");
  if (type === "email") {
    return getUserInfo.email;
  } else if (type === "status") {
    return getUserInfo.status;
  
  } else if (type === "name") {
    return getUserInfo.name;
  } else if (type === "id") {
    return getUserInfo;
  } else if (type === "welcomemessage") {
    return getUserInfo.welcomemessage;
  }
};
// const decodeEncodedItem = (decodedItem) => {
//   if (decodedItem !== "" && decodedItem !== undefined && decodedItem !== null) {
//     decodedItem = JSON.parse(decodedItem);
//     decodedItem = jwt.decode(
//       decodedItem,
//       process.env.REACT_APP_JWT_SECRET,
//       "HS512"
//     );
//   }
//   return decodedItem;
// };
