import React from "react";
import "./login.css";
import LoginBgImg from "../../../assets/images/login-bg.png";
import LogoImg from "../../../assets/images/logo.png";
import oilIndustryImg from "../../../assets/images/oil-industry-elements.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { ThemeProvider } from "@emotion/react";
import { Button, createTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showToaster } from "../../Services/helper";
import { psoOptimization } from "../../Services/api-services";
const theme = createTheme({
  palette: {
    primary: {
      main: "#176C44",
    },
    secondary: {
      main: "#09A359",
    },
  },
});
export default function Login() {
  let history = useHistory();
  const handleButtonClick = () => {
    history.push("/overview");
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .required("Email is Required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: (values) => {
      try {
        console.log(values, "valuesvaluesvalues");
        history.push("/overview");
        // let payload = {};
        // psoOptimization(values).then(async (response) => {
        //   if (
        //     response !== "" &&
        //     response !== undefined &&
        //     response !== null &&
        //     response.data !== null &&
        //     response.data !== undefined
        //   ) {
        //     showToaster("New details added successfully", "success");
        //     console.log(response, "ressssssssssss");

        //     // setGetDetails(response.data.data)
        //   } else {
        //     showToaster("Something went wrong", "warning");
        //   }
        // });
      } catch (e) {
        console.log(e);
        formik.resetForm();
        showToaster("something went wrong", "warning");
      }
    },
  });

  return (
    <div className="outer-container">
      <div className="inner-container">
        <div className="left-container">
          <div>
            <img src={oilIndustryImg} className="oilImage" />
          </div>
          <div className="img-text">
            Drilling Optimization
            <div className="img-text-sub">AI engine</div>
          </div>
        </div>
        {/* <div>s</div> */}
        <div
          style={{
            width: ".5px", // Vertical line width
            height: "90%", // Full height of the parent container
            backgroundColor: "#0000005c", // Color of the vertical line
            opacity: "1", // Opacity of the line
            transform: "rotate(0deg)", // No rotation needed for vertical line
          }}
        />

        <div className="right-container">
          <form onSubmit={formik.handleSubmit}>
            <div className="header-text">
              Drilling Optimization
              <div className="header-text-sub">AI engine</div>
            </div>
            <div className="sub-header-text">
              Hello Again !
              <div className="sub-header-text-sub">
                Please sign in to continue. Your account awaits
              </div>
            </div>
            <div className="input-container">
              <div>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  type="text"
                  id="email"
                  name="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start">
                          <MailOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "340px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#176C44", // Default border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#176C44", // Label color
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#176C44", // Icon color
                    },
                  }}
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  size="small"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="inputerrormsg">{formik.errors.email}</div>
                ) : null}
              </div>
              <div>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type="password"
                  id="password"
                  name="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="start">
                          <LockIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "340px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#176C44", // Default border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#176C44", // Label color
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#176C44", // Icon color
                    },
                  }}
                  fullWidth
                  label="Password"
                  variant="outlined"
                  size="small"
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="inputerrormsg">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="remember-text">
                <div>
                  <CheckBoxOutlineBlankIcon
                    sx={{ width: "18px", color: "#176C44" }}
                  />
                </div>
                <div>Remember me</div>
              </div>
            </div>
            <div className="button-container">
              <div>
                <ThemeProvider theme={theme}>
                  <Button
                    type="submit"
                    sx={{
                      width: "130px",
                      borderRadius: "10px",
                      fontWeight: "600",
                      colour: "white",
                    }}
                    variant="contained"
                    // onClick={handleButtonClick}
                  >
                    Login
                  </Button>
                </ThemeProvider>
              </div>

              <div className="bottom-text">
                Don’t have an account ?{" "}
                <span className="regiser-text">Register</span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="footer-container">
        <img src={LogoImg} className="logo-mage" />
      </div>
    </div>
  );
}
