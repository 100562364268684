import { React, useState, useEffect } from "react";
import NavBar from "../Navbar/navbar";
import Header from "../Headers/header";
import homeStyles from "./home.module.css";
import TextField from "@mui/material/TextField";
import { Button, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { showToaster } from "../../Services/helper";
import { useFormik } from "formik";
import {
  psoOptimization,
  fetchWellIds,
  fetchWellData,
} from "../../Services/api-services";
import * as Yup from "yup";
import PageLoader from "../../Services/pageLoader";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import Valuechip from "./valuechip";
import { useLocation } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "#176C44",
    },
    secondary: {
      main: "#09A359",
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function Home(props) {
  const [optimizeValues, setOptimizeValues] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const theme = useTheme();
  const [wellId, setWellId] = useState([]);
  const [wellIdList, setWellIdList] = useState([]);
  const [wellDetails, setWellDetails] = useState();
  const location = useLocation();
  const myStateVariable = location.state;
  let history = useHistory();

  useEffect(() => {
    // Check if there's any state passed
    if (location.state) {
      // Do something with the state, if needed
      console.log(location.state);

      // Clear the state by replacing the current entry in the history stack
      history.replace({
        pathname: location.pathname,
        state: null,
      });
    }
  }, []);
  const handleWellIdChange = async (event) => {
    // setWellId()
    await setWellId(event.target.value);
    await getWellDetails(event.target.value);
    setWellDetails(undefined);
    setOptimizeValues(undefined);
  };

  const getWellDetails = (value) => {
    setPageLoad(true);
    try {
      const body = {
        well_id: value,
      };
      fetchWellData(body).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          setPageLoad(false);
          setWellDetails(response.data.data);
          formik.setFieldValue("current_Depth", response?.data?.data?.depth_m);
          formik.setFieldValue("pit_Total", response?.data?.data?.pit_total_m3);
          formik.setFieldValue(
            "flow_Out",
            response?.data?.data?.flow_out_percent
          );
          formik.setFieldValue("h2s_Floor", response?.data?.data?.h2s_floor);
          formik.setFieldValue("h2s_Ceiling", response?.data?.data?.h2s_cellar);
          formik.setFieldValue("h2s_Pits", response?.data?.data?.h2s_pits);
          formik.setFieldValue("temp_Out", response?.data?.data?.temp_out_c);
          formik.setFieldValue("well_ID", value);
          formik.setFieldValue(
            "target_Depth",
            response?.data?.data?.target_depth
          );
          if (myStateVariable !== null && myStateVariable !== undefined) {
            dynamicApiCallOptimize(response?.data?.data);
          }
          // setGetDetails(response.data.data)
        } else {
          setPageLoad(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setPageLoad(false);
      console.log(e);
      formik.resetForm();
      showToaster("something went wrong", "warning");
    }
  };

  const dynamicApiCallOptimize = (values) => {
    setPageLoad(true);
    try {
      console.log(values, "valuesvaluesvalues");
      const requestData = {
        well_ID: parseFloat(values.well_id),
        current_Depth: parseFloat(values.depth_m),
        target_Depth: parseFloat(values.target_depth),
        temp_Out: parseFloat(values.temp_out_c),
        pit_Total: parseFloat(values.pit_total_m3),
        flow_Out: parseFloat(values.flow_out_percent),
        h2s_Floor: parseFloat(values.h2s_floor),
        h2s_Ceiling: parseFloat(values.h2s_cellar),
        h2s_Pits: parseFloat(values.h2s_pits),
      };
      psoOptimization(requestData).then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          setPageLoad(false);
          showToaster("New details added successfully", "success");
          console.log(response, "ressssssssssss");
          setOptimizeValues(response.data);
          // setGetDetails(response.data.data)
        } else {
          setPageLoad(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setPageLoad(false);
      console.log(e);
      formik.resetForm();
      showToaster("something went wrong", "warning");
    }
  };

  const getWellId = () => {
    setPageLoad(true);
    try {
      fetchWellIds().then(async (response) => {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== null &&
          response.data !== undefined
        ) {
          // setPageLoad(false);
          // console.log("response.data",response.data.data)
          setWellIdList(response.data.data);
          setPageLoad(false);
          // setGetDetails(response.data.data)
        } else {
          setPageLoad(false);
          showToaster("Something went wrong", "warning");
        }
      });
    } catch (e) {
      setPageLoad(false);
      console.log(e);
      formik.resetForm();
      showToaster("something went wrong", "warning");
    }
  };
  const formik = useFormik({
    initialValues: {
      well_ID: "",
      current_Depth: "",
      target_Depth: "",
      temp_Out: "",
      pit_Total: "",
      flow_Out: "",
      h2s_Floor: "",
      h2s_Ceiling: "",
      h2s_Pits: "",
    },
    validationSchema: Yup.object().shape({
      well_ID: Yup.string().required("well ID is required"),
      current_Depth: Yup.string().required("Current Depth is required"),
      target_Depth: Yup.string().required("Target Depth is required"),
      temp_Out: Yup.string().required("Temp Out is required"),
      pit_Total: Yup.string().required("Pit Total is required"),
      flow_Out: Yup.string().required("Flow Out is required"),
      h2s_Floor: Yup.string().required("h2s Floor is required"),
      h2s_Ceiling: Yup.string().required("h2s Ceiling is required"),
      h2s_Pits: Yup.string().required("h2s Pits is required"),
    }),

    onSubmit: (values) => {
      console.log("values", values);
      setPageLoad(true);

      try {
        console.log(values, "valuesvaluesvalues");
        const requestData = {
          well_ID: parseFloat(values.well_ID),
          current_Depth: parseFloat(values.current_Depth),
          target_Depth: parseFloat(values.target_Depth),
          temp_Out: parseFloat(values.temp_Out),
          pit_Total: parseFloat(values.pit_Total),
          flow_Out: parseFloat(values.flow_Out),
          h2s_Floor: parseFloat(values.h2s_Floor),
          h2s_Ceiling: parseFloat(values.h2s_Ceiling),
          h2s_Pits: parseFloat(values.h2s_Pits),
        };
        psoOptimization(requestData).then(async (response) => {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== null &&
            response.data !== undefined
          ) {
            setPageLoad(false);
            showToaster("New details added successfully", "success");
            console.log(response, "ressssssssssss");
            setOptimizeValues(response.data);
            // setGetDetails(response.data.data)
          } else {
            setPageLoad(false);
            showToaster("Something went wrong", "warning");
          }
        });
      } catch (e) {
        setPageLoad(false);
        console.log(e);
        formik.resetForm();
        showToaster("something went wrong", "warning");
      }
    },
  });
  useEffect(() => {
    if (
      myStateVariable !== null &&
      myStateVariable !== undefined &&
      myStateVariable !== ""
    ) {
      setWellId(myStateVariable);
      getWellDetails(myStateVariable);
    }
  }, [myStateVariable]);
  useEffect(() => {
    getWellId();
  }, []);

  return (
    <>
      <PageLoader showLoader={pageLoad} />

      <div className={homeStyles.dashboard_container}>
        <div className={homeStyles.nav_container}>
          <NavBar />
        </div>
        <div className={homeStyles.dashboard_item_container}>
          <Header />
          {/* <div className="name-container">👋🏼 Hello Evano</div> */}
          <div className={homeStyles.outer_countiner}>
            <form onSubmit={formik.handleSubmit}>
              <div className={homeStyles.input_container_dashboard}>
                <span>
                  <div className={homeStyles.heading}>
                    Drilling Optimisation
                  </div>
                  <div className={homeStyles.description}>
                    Enter the measured values to get optimisation result.
                  </div>
                </span>
                <div></div>
                <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-name-label">
                      Select Well ID
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      // multiple
                      value={wellId}
                      onChange={(event) => handleWellIdChange(event)}
                      input={<OutlinedInput label="Select Well ID" />}
                      // MenuProps={MenuProps}
                    >
                      {wellIdList.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          // style={getStyles(name, wellId, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.current_Depth}
                    type="text"
                    id="current_Depth"
                    name="current_Depth"
                    fullWidth
                    label="Current Depth (m)"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.current_Depth &&
                  formik.errors.current_Depth ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.current_Depth}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.target_Depth}
                    type="text"
                    id="target_Depth"
                    name="target_Depth"
                    fullWidth
                    label="Target Depth (m)"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.target_Depth && formik.errors.target_Depth ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.target_Depth}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pit_Total}
                    type="text"
                    id="pit_Total"
                    name="pit_Total"
                    fullWidth
                    label="Pit Total (m³)  "
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.pit_Total && formik.errors.pit_Total ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.pit_Total}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.flow_Out}
                    type="text"
                    id="flow_Out"
                    name="flow_Out"
                    fullWidth
                    label="Flow Out (%) "
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.flow_Out && formik.errors.flow_Out ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.flow_Out}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.h2s_Floor}
                    type="text"
                    id="h2s_Floor"
                    name="h2s_Floor"
                    fullWidth
                    label="H2S Floor (ppb)"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.h2s_Floor && formik.errors.h2s_Floor ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.h2s_Floor}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.h2s_Ceiling}
                    type="text"
                    id="h2s_Ceiling"
                    name="h2s_Ceiling"
                    fullWidth
                    label="H2S Cellar (ppb)"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.h2s_Ceiling && formik.errors.h2s_Ceiling ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.h2s_Ceiling}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.h2s_Pits}
                    type="text"
                    id="h2s_Pits"
                    name="h2s_Pits"
                    fullWidth
                    label="H2S Pits (ppb)"
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.h2s_Pits && formik.errors.h2s_Pits ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.h2s_Pits}
                    </div>
                  ) : null}
                </div>
                <div>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.temp_Out}
                    type="text"
                    id="temp_Out"
                    name="temp_Out"
                    fullWidth
                    label="Temp Out (°C) "
                    variant="outlined"
                    size="small"
                  />
                  {formik.touched.temp_Out && formik.errors.temp_Out ? (
                    <div className={homeStyles.inputerrormsg}>
                      {formik.errors.temp_Out}
                    </div>
                  ) : null}
                </div>

                <div className={homeStyles.button_container}>
                  <ThemeProvider theme={theme}>
                    <Button
                      disabled={pageLoad}
                      type="submit"
                      startIcon={<WhatshotIcon />}
                      sx={{
                        width: "130px",
                        borderRadius: "10px",
                        fontWeight: "600",
                        colour: "white",
                      }}
                      variant="contained"
                      
                    >
                      Optimize
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </form>
          </div>
          {wellDetails !== undefined ? (
            <div className={homeStyles.result_container_value}>
              <div className={homeStyles.response_header}>
                Optimised Machine Values
              </div>
              <div
                style={{
                  width: "80%",
                  borderBottom: "1px solid rgb(204, 204, 204)",
                  margin: "20px auto",
                }}
              ></div>

              <div className={homeStyles.response_conainer}>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>
                    Weight on Bit
                  </div>
                  <div className={homeStyles.response_value}>
                    {optimizeValues === undefined ? (
                      <>{wellDetails?.weight_on_bit_kg?.toFixed(2)} Kg</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.weight_on_bit_kg?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.weight_On_Bit?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.weight_On_Bit?.value
                          ).toFixed(2)}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>
                    Temperature IN
                  </div>
                  <div className={homeStyles.response_value}>
                    {optimizeValues === undefined ? (
                      <>{wellDetails?.temp_in_c?.toFixed(2)} °C</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.temp_in_c?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.temp_In?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.temp_In?.value
                          ).toFixed(2)}
                        />
                    
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>
                    Pump Pressure
                  </div>
                  <div className={homeStyles.response_value}>
                   
                    {optimizeValues === undefined ? (
                      <>{wellDetails?.pump_press_kpa?.toFixed(2)} kPa</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.pump_press_kpa?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.pump_Press?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.pump_Press?.value
                          ).toFixed(2)}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>Hookload</div>
                  <div className={homeStyles.response_value}>
                    {optimizeValues === undefined ? (
                      <>{wellDetails?.hookload_kg?.toFixed(2)} kg</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.hookload_kg?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.hook_Load?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.hook_Load?.value
                          ).toFixed(2)}
                        />
                       
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>
                    Surface Torque
                  </div>
                  <div className={homeStyles.response_value}>
                   

                    {optimizeValues === undefined ? (
                      <>{wellDetails?.surface_torque_kpa?.toFixed(2)} kPa</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.surface_torque_kpa?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.surface_Torque?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.surface_Torque?.value
                          ).toFixed(2)}
                        />
                       
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>
                    Rotary Speed
                  </div>
                  <div className={homeStyles.response_value}>
                  
                    {optimizeValues === undefined ? (
                      <>{wellDetails?.rotary_speed_rpm?.toFixed(2)} rpm</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.rotary_speed_rpm?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.rotary_Speed?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.rotary_Speed?.value
                          ).toFixed(2)}
                        />
                      
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>Flow In</div>
                  <div className={homeStyles.response_value}>
                   
                    {optimizeValues === undefined ? (
                      <>
                        {wellDetails?.flow_in_liters_min?.toFixed(2)} liters/min
                      </>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.flow_in_liters_min?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.flow_In?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.flow_In?.value
                          ).toFixed(2)}
                        />
                      
                      </>
                    )}
                  </div>
                </div>
                <div className={homeStyles.value_container}>
                  <div className={homeStyles.response_head_value}>
                    Wellhead Pressure
                  </div>
                  <div className={homeStyles.response_value}>
                   

                    {optimizeValues === undefined && wellDetails ? (
                      <>{wellDetails?.wh_pressure_kpa?.toFixed(2)} kPa</>
                    ) : (
                      <>
                        <Valuechip
                          valuePrimary={parseFloat(
                            wellDetails?.wh_pressure_kpa?.toFixed(2)
                          ).toFixed(2)}
                          unit={optimizeValues?.wellhead_Pressure?.unit}
                          valueSecondary={parseFloat(
                            optimizeValues?.wellhead_Pressure?.value
                          ).toFixed(2)}
                        />
                       
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={homeStyles.result_button_container}>
                {optimizeValues === undefined && wellDetails ? (
                  <div className={homeStyles.result_button}>
                    Expected ROP{" "}
                    <span className={homeStyles.response_value}>
                      {parseFloat(wellDetails?.rop_m_per_hr.toFixed(2))} m/h
                    </span>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className={homeStyles.result_button}
                      style={{ borderColor: "yellow", border: "2px" }}
                    >
                      Default ROP{" "}
                      <span
                        className={homeStyles.response_value}
                        style={{ color: "#1565c0" }}
                      >
                        {parseFloat(wellDetails?.rop_m_per_hr.toFixed(2))} m/h
                      </span>
                    </div>
                    <div className={homeStyles.result_button}>
                      Expected ROP{" "}
                      <span
                        className={homeStyles.response_value}
                        style={{ color: "green" }}
                      >
                        {optimizeValues &&
                          parseFloat(
                            optimizeValues?.expected_ROP?.value.toFixed(2)
                          )}
                      </span>
                      {optimizeValues?.expected_ROP?.unit}
                    </div>
                  </div>
                )}
                {optimizeValues !== undefined && (
                  <div
                    className={homeStyles.result_button_container}
                    style={{
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "center", // Center horizontally
                      alignItems: "center", // Center vertically
                      textAlign: "center", // Center text within the spans
                      height: "100%", // Ensure the container has enough height to center vertically
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>
                      The estimated time required to drill&nbsp;
                    </span>
                    <span style={{ fontWeight: "500", color: "#176C44" }}>
                      {`${parseFloat(
                        optimizeValues?.distance_To_Target_Depth?.value.toFixed(
                          2
                        )
                      )} ${optimizeValues?.distance_To_Target_Depth?.unit}`}
                    </span>
                    <span style={{ fontWeight: "500" }}>
                      &nbsp;with this expected ROP is&nbsp;
                    </span>
                    <span style={{ fontWeight: "500", color: "#176C44" }}>
                      {optimizeValues?.expected_time}
                    </span>
                    .
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={homeStyles.result_container}>
              <div className={homeStyles.response_header}>
                Optimised Machine Values
              </div>
              <div className={homeStyles.response_conainer}>
                <div className={homeStyles.value_container}>
                  <div>Weight on Bit</div>
                  <div>---- Kg</div>
                </div>
                <div>
                  <div>Temperature IN</div>
                  <div>-- °C</div>
                </div>
                <div>
                  <div>Pump Pressure</div>
                  <div>-- kPa</div>
                </div>
                <div>
                  <div>Hookload</div>
                  <div>-- kg</div>
                </div>
                <div>
                  <div>Surface Torque</div>
                  <div>--- kPa</div>
                </div>
                <div>
                  <div>Rotary Speed</div>
                  <div>---- rpm</div>
                </div>
                <div>
                  <div>Flow In</div>
                  <div>-- liters/min</div>
                </div>
                <div>
                  <div>Wellhead Pressure</div>
                  <div>-- kPa</div>
                </div>
              </div>
              <div className={homeStyles.result_button_container}>
                <div className={homeStyles.result_button}>
                  Expected ROP --- m/hr
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
